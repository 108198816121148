//related to homepages only
import axios from "axios";
import {email, helpers, maxLength, minLength, numeric, required} from "@vuelidate/validators";
import {emailSymbols} from "./validations";
import {findGetParameter} from "./commonGeneral";

export let getStartedFormFields = {
    email: '',
    requested_amount: '2500',
    ssn: ''
}

export let getStartedFormValidations = {
    fields: {
        email: {
            required,
            email: helpers.withMessage('Please enter a valid email address', email),
            emailSymbols: helpers.withMessage('Not allowed chars', emailSymbols)
        },
        requested_amount: {required, numeric},
        ssn: {required, numeric, minLengthValue: minLength(4), maxLengthValue: maxLength(4)},
    }
}

function isMobileByWidth() {
    return window.matchMedia("(max-width: 768px)").matches;
}
function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}


export function getStarted() {
    this.v$.$touch()
    if (!this.v$.$errors.length) {
        this.$cookies.remove('requestedamount');
        this.$cookies.remove('customerDataMain');
        this.$cookies.remove('customerState');

        const click_id = globalParams.click_id || window.clickId || this.$cookies.get('click_id') || null;

        try {
            axios
                .post(import.meta.env.VITE_API_GET_STARTED, {
                    ...this.fields,
                    click_id,
                })
                .then(async (response) => {
                    if (response.data && response.data.ip_check !== undefined) {
                        this.fields.risk_processed = response.data.ip_check.risk_processed;
                        this.fields.risk = response.data.ip_check.risk
                        globalParams.risk = response.data.ip_check.risk
                        globalParams.risk = response.data.ip_check.risk_processed
                        this.$cookies.set('risk', response.data.ip_check.risk)
                        this.$cookies.set('risk_processed', response.data.ip_check.risk_processed)

                    }
                    if (response.status === 200) {
                        if (window.isSharedCookie === 1) {
                            axios.post(import.meta.env.VITE_URL_SET_FINGERPRINT_COOKIES, {
                                'device_id': globalParams.device_id ?? this.$cookies.get(globalParams.cookie_device_id_name) ?? this.$cookies.get('x3GhSd9234tewq34'),
                                'email': this.fields.email,
                                'risk_finger_print': globalParams.risk_finger_print ?? this.$cookies.get('risk_finger_print'),
                                'risk_processed_finger_print': globalParams.risk_processed_finger_print ?? this.$cookies.get('risk_processed_finger_print'),
                                'is_crawler': globalParams.is_crawler ?? this.$cookies.get('is_crawler'),

                            }, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(response => {
                                    this.$cookies.set('email', this.fields.email)
                                    globalParams.requested_amount = this.fields.requested_amount;
                                    globalParams.email = this.fields.email;
                                    window.location.href = `/reapply?email=${this.fields.email}&ssn=${this.fields.ssn}${globalParams.click_id ? `&click_id=${globalParams.click_id}` : ''}`;
                                })
                                .catch((e) => {
                                    console.error(e)
                                });
                        } else {
                            this.$cookies.set('email', this.fields.email)
                            globalParams.requested_amount = this.fields.requested_amount;
                            globalParams.email = this.fields.email;
                            window.location.href = `/reapply?email=${this.fields.email}&ssn=${this.fields.ssn}${globalParams.click_id ? `&click_id=${globalParams.click_id}` : ''}`;
                        }
                    }
                })
                .catch((error) => {
                    if (error.message === 'Network Error') {
                        return
                    }
                    document.getElementById('invalid-ssn').classList.add('d-none');
                    if (error.response.status === 404) {
                        let fieldsGetStarted = {
                            'email': this.fields.email,
                            'requested_amount': this.fields.requested_amount,
                        }
                        this.$cookies.set('fieldsGetStarted', fieldsGetStarted, 60 * 60);
                        window.location.href = '/form';
                    } else if (error.response.status === 422) {
                        const {errors, message} = error.response.data || {};

                        const showError = (elementId, field, errorMessage) => {
                            const element = document.getElementById(elementId);
                            if (element) {
                                element.classList.remove('d-none');
                                element.innerHTML = `<p class="text-left error mb-0">${errorMessage}</p>`;
                            }
                            if (field) {
                                this.v$.fields[field].error = true;
                            }
                        };

                        if (errors?.email) {
                            showError('invalid-email', 'email', errors.email[0]);
                            document.getElementById('first_field')?.classList.remove('mb-3');
                            document.getElementById('last_field')?.classList.add('mb-3');
                        } else if (message === 'This email has been deleted, please contact support!') {
                            showError('invalid-email', 'email', message);
                            document.getElementById('first_field')?.classList.remove('mb-3');
                            document.getElementById('last_field')?.classList.add('mb-3');
                        } else {
                            showError('invalid-ssn', 'ssn', message || 'User not found');
                            document.getElementById('last_field')?.classList.remove('mb-3');
                        }
                    } else if (error.response.status === 429) {
                        document.getElementById('invalid-ssn').classList.remove('d-none');
                        document.getElementById('invalid-ssn').innerHTML = '<p class="text-left error mb-0">Too many attempts! Try it later.</p>';
                        document.getElementById('last_field').classList.remove('mb-3');

                    }

                });
        } catch (e) {
            this.saveError(e);
        }
    }
}

export function checkEmail() {
    try {
        axios
            .post(import.meta.env.VITE_API_SEARCH_REAPPLY, {
                email: this.fields.email
            })
            .then((response) => {
                if (response.data && response.data.ip_check !== undefined) {
                    this.fields.risk_processed = response.data.ip_check.risk_processed;
                    this.fields.risk = response.data.ip_check.risk
                    globalParams.risk = response.data.ip_check.risk
                    globalParams.risk_processed = response.data.ip_check.risk_processed
                    this.$cookies.set('risk', response.data.ip_check.risk)
                    this.$cookies.set('risk_processed', response.data.ip_check.risk_processed)
                }
                if (response.data.status !== 'not found') {
                    globalParams.requested_amount = this.fields.requested_amount;
                    globalParams.email = this.fields.email;
                    window.location.href = '/reapply?email=' + this.fields.email + (globalParams.click_id === null ? '' : '&click_id=' + globalParams.click_id);
                }
            })
            .catch((error) => {
                this.saveError(error);
            });
    } catch (e) {
        this.saveError(e);
    }
}

